import React from "react"
import cx from "classnames"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import { IMG } from "../const"
import { TECHNOLOGIES, OPTIONS } from "../data/outsail"
import "../assets/styles/commons/_heading-numbers.scss"
import "../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/outsail.module.scss"
const FeedbackForm = loadable(() =>
  import("../components/outsail/FeedbackForm")
)

const Outsail = () => (
  <Layout pageTitle="OutSail">
    {({
      handleOpenConfirmation,
      isOpenConfirmation,
      setConfirmationOption,
    }) => {
      return (
        <main className={cx(styles.outsailBlock, "glossy")}>
          <article>
            <div className={styles.heading}>
              <h1>The ideal web service for finding the right HR software</h1>
              <p>
                LaSoft successfully developed and delivered a fully functional
                product that optimizes costs by <mark>$450,000</mark> annually
                and reduces users' time searching for the best HR vendor by an
                astounding <mark>52%</mark>.
              </p>
              <div className={styles.linkWrapper}>
                <a
                  href="https://www.outsail.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.toOutsail}
                >
                  Visit OutSail
                </a>
              </div>
            </div>

            <div className={styles.technologiesBlock}>
              <ul className={styles.details}>
                <li>
                  <dl>
                    <dt>Industry:</dt>
                    <dd>HR Tech</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Country:</dt>
                    <dd>USA</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Company:</dt>
                    <dd className={styles.logoWrapper}>
                      <img
                        src={`${IMG.OUTSAIL}/logo-Outsail.svg`}
                        alt="Logo OutSail"
                        width="60"
                        height="22"
                      />
                    </dd>
                  </dl>
                </li>
              </ul>
              <ul className="common__list__techLogos-rounded">
                {TECHNOLOGIES.map(technology => (
                  <li key={technology.name}>
                    <figure>
                      <img
                        src={`${IMG.ICONS}/logos/${technology.icon}`}
                        width="32"
                        height="32"
                        alt={technology.name}
                      />
                      <figcaption>{technology.name}</figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.feedback}>
              <picture>
                <source
                  srcSet={`${IMG.OUTSAIL}/Brett-Ungashick.avif,  ${IMG.OUTSAIL}/Brett-Ungashick@2x.avif 2x, ${IMG.OUTSAIL}/Brett-Ungashick@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  srcSet={`${IMG.OUTSAIL}/Brett-Ungashick.webp,  ${IMG.OUTSAIL}/Brett-Ungashick@2x.webp 2x, ${IMG.OUTSAIL}/Brett-Ungashick@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  srcSet={`${IMG.OUTSAIL}/Brett-Ungashick.jpg,   ${IMG.OUTSAIL}/Brett-Ungashick@2x.jpg 2x,  ${IMG.OUTSAIL}/Brett-Ungashick@3x.jpg 3x`}
                />
                <img
                  src={`${IMG.OUTSAIL}/Brett-Ungashick.jpg`}
                  alt="Brett Ungashick"
                  width="150"
                  height="150"
                  loading="lazy"
                />
              </picture>
              <div>
                <blockquote>
                  The team's work ethic and communication are of the highest
                  quality. LaSoft has created a user-friendly web experience
                  that has resulted in increased customers and more efficient
                  purchases. The team meets the project's deadlines and offers
                  high-quality communication through email and biweekly virtual
                  meetings. LaSoft responds promptly to the client's requests.
                </blockquote>
                <p className={styles.name}>Brett Ungashick</p>
                <p className={styles.jobTitle}>Founder of OutSail</p>
              </div>
            </div>

            <div className={styles.desktopImgWrapper}>
              <picture>
                <source
                  media="(max-width: 375px)"
                  srcSet={`${IMG.OUTSAIL}/mobile-main-team.avif,  ${IMG.OUTSAIL}/mobile-main-team@2x.avif 2x, ${IMG.OUTSAIL}/mobile-main-team@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(max-width: 375px)"
                  srcSet={`${IMG.OUTSAIL}/mobile-main-team.webp,  ${IMG.OUTSAIL}/mobile-main-team@2x.webp 2x, ${IMG.OUTSAIL}/mobile-main-team@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(max-width: 375px)"
                  srcSet={`${IMG.OUTSAIL}/mobile-main-team.png,   ${IMG.OUTSAIL}/mobile-main-team@2x.png 2x,  ${IMG.OUTSAIL}/mobile-main-team@3x.png 3x`}
                />

                <source
                  media="(min-width: 376px) and (max-width: 1024px)"
                  srcSet={`${IMG.OUTSAIL}/main-team-tablet.avif,  ${IMG.OUTSAIL}/main-team-tablet@2x.avif 2x, ${IMG.OUTSAIL}/main-team-tablet@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 376px) and (max-width: 1024px)"
                  srcSet={`${IMG.OUTSAIL}/main-team-tablet.webp,  ${IMG.OUTSAIL}/main-team-tablet@2x.webp 2x, ${IMG.OUTSAIL}/main-team-tablet@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 376px) and (max-width: 1024px)"
                  srcSet={`${IMG.OUTSAIL}/main-team-tablet.png,   ${IMG.OUTSAIL}/main-team-tablet@2x.png 2x,  ${IMG.OUTSAIL}/main-team-tablet@3x.png 3x`}
                />

                <source
                  media="(min-width: 1025px)"
                  srcSet={`${IMG.OUTSAIL}/main-team.avif,  ${IMG.OUTSAIL}/main-team@2x.avif 2x, ${IMG.OUTSAIL}/main-team@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 1025px)"
                  srcSet={`${IMG.OUTSAIL}/main-team.webp,  ${IMG.OUTSAIL}/main-team@2x.webp 2x, ${IMG.OUTSAIL}/main-team@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 1025px)"
                  srcSet={`${IMG.OUTSAIL}/main-team.png,   ${IMG.OUTSAIL}/main-team@2x.png 2x,  ${IMG.OUTSAIL}/main-team@3x.png 3x`}
                />
                <img
                  src={`${IMG.OUTSAIL}/main-team.png`}
                  alt=""
                  width="1250"
                  height="614"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className={styles.listsBlock}>
              <ul className={styles.mainList}>
                {OPTIONS.map(item => (
                  <li key={item.id}>
                    <div>
                      <div className={styles.mainText}>
                        <h2>{item.title}</h2>
                        <p>{item.text}</p>
                      </div>
                      {item.subtext && (
                        <p className={styles.subtext}>{item.subtext}</p>
                      )}
                      {item.img && (
                        <div className={styles.mainImg}>{item.img}</div>
                      )}
                      {item.list && <div>{item.list}</div>}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <section className={styles.contactForm}>
              <FeedbackForm
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </article>
        </main>
      )
    }}
  </Layout>
)

export default Outsail
